<template>
  <div class="container-fluid pb-4 maxw">
    <h1 class="py-5">{{ $t('dashboardsSection.dashboardTitle') }}</h1>
    <b-button class="my-3" @click="getToken()">{{ $t('dashboardsSection.dashboardToken') }}</b-button><br />
    <b-button class="my-3" @click="openDashTab()">KMF Dashboard</b-button>
    <b-container class="my-4 py-2 mtk-shadow bg-light">
      <cool-chart />
    </b-container>
    <b-container class="my-4 py-2 mtk-shadow bg-light">
      <rgb-chart />
    </b-container>
    <b-container class="my-4 py-2 mtk-shadow bg-light">
      <area-chart />
    </b-container>
    <b-container class="my-4 py-2 mtk-shadow bg-light">
      <b-row>
        <b-col><donut-chart /></b-col>
        <b-col><radial-bar-chart /></b-col>
      </b-row>
    </b-container>
    <b-container class="my-4 py-2 mtk-shadow bg-light">
      <b-col><column-chart /></b-col>
    </b-container>
  </div>
</template>

<script>
import Vue from 'vue';

import VueApexCharts from 'vue-apexcharts';
Vue.use(VueApexCharts);
Vue.component('apexchart', VueApexCharts);

import { getInstance } from '../auth';
import AreaChart from '../components/charts/AreaChart.vue';
import ColumnChart from '../components/charts/ColumnChart.vue';
import DonutChart from '../components/charts/DonutChart.vue';
import RadialBarChart from '../components/charts/RadialBarChart.vue';
import CoolChart from '../components/CoolChart.vue';

import RgbChart from '../components/RgbChart.vue';

export default {
  name: 'Dashboards',
  components: {
    RgbChart,
    CoolChart,
    AreaChart,
    ColumnChart,
    DonutChart,
    RadialBarChart,
  },
  methods: {
    async getToken() {
      const token = await getInstance().getTokenSilently();
      console.log(token);
    },
    openDashTab() {
      window.open(
        'https://elk-1.mitakus.eu/s/kmf/app/dashboards#/view/2c377080-6090-11eb-942b-6f889344464b?embed=true&_g=(filters%3A!()%2CrefreshInterval%3A(pause%3A!t%2Cvalue%3A0)%2Ctime%3A(from%3Anow-15m%2Cto%3Anow))&show-time-filter=true',
        '_blank'
      );
    },
  },
};
</script>

<style></style>
