<template>
  <div>
    <apexchart width="350" type="radialBar" :options="chartOptions" :series="series"></apexchart>
  </div>
</template>

<script>
export default {
  name: 'RadialBarChart',
  data: function() {
    return {
      chartOptions: {
        labels: ['Percent'],
      },
      series: [81, 53, 42],
    };
  },
};
</script>