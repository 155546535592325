<template>
  <div class="example">
    <apexchart
      width="100%"
      height="100%"
      type="area"
      :options="chartOptions"
      :series="series"
    ></apexchart>
    <button @click="refreshChart">{{ $t('btnOptions.refresh') }}</button>
  </div>
</template>

<script>
import axios from 'axios';

export default {
  name: 'AreaExample',
  data: function() {
    return {
      chartOptions: {
        dataLabels: {
          enabled: false,
        },
        stroke: {
          curve: 'smooth',
        },
        xaxis: {
          categories: [],
        },
        tooltip: {
          fixed: {
            enabled: false,
            position: 'topRight',
          },
        },
      },
      series: [],
    };
  },
  mounted() {
    this.refreshChart();
  },
  methods: {
    refreshChart() {
      axios.get('http://localhost:5000/api/test/zdv').then((response) => {
        this.series = response.data.series;
        this.chartOptions.xaxis.categories = response.data.xaxis[0].categories;
        // console.log(this.series);
      });
    },
  },
};
</script>