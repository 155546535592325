<template>
  <div>
    <apexchart
      width="100%"
      height="300px"
      type="line"
      :options="chartOptions"
      :series="series"
    ></apexchart>
    <button @click="refreshChart">{{ $t('btnOptions.refresh') }}</button>
  </div>
</template>

<script>
import axios from 'axios';
export default {
  data: function() {
    return {
      chartOptions: {
        chart: {
          id: 'vuechart-example',
        },
        title: {
          text: 'KMF Prognose',
          align: 'left',
          margin: 10,
          offsetX: 5,
          offsetY: 0,
          style: {
            fontSize: '14px',
            fontWeight: 'bold',
            color: '#263238',
          },
        },
        stroke: {
          curve: 'smooth',
          width: 2,
        },
        xaxis: {
          type: 'datetime',
        },
      },
      series: [],
    };
  },
  methods: {
    refreshChart() {
      axios.get('http://localhost:5000/api/private-permissions/zdv').then((response) => {
        this.series = response.data;
        // console.log(this.series);
      });
    },
  },
  mounted() {
    this.refreshChart();
  },
};
</script>

<style></style>